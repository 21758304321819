:root {
  --w-standard: 80rem;
  --w-example-wrapped: 600px;

  --block-margin-vertical: 3.75rem;
  --block-padding-vertical: 1.875rem;

  --c-aero-blue: #abffd9;
  --c-alto: #dfdfdf;
  --c-athens-gray: #f8f8f9;
  --c-black: #000;
  --c-black-o2: rgba(0, 0, 0, 0.2);
  --c-black-o4: rgba(0, 0, 0, 0.4);
  --c-black-o6: rgba(0, 0, 0, 0.6);
  --c-black-o8: rgba(0, 0, 0, 0.8);
  --c-deep-cove: #061a40;
  --c-deep-cove-o2: rgba(6, 26, 64, 0.2);
  --c-deep-cove-o3: rgba(6, 26, 64, 0.3);
  --c-deep-cove-o6: rgba(6, 26, 64, 0.6);
  --c-deep-cove-o8: rgba(6, 26, 64, 0.8);
  --c-dove-gray: #636363;
  --c-egg-sour: #fff3dd;
  --c-gallery: #eeeeee;
  --c-jacarta: #2f285b;
  --c-matisse: #1c5298;
  --c-matisse-darker: #03397f;
  --c-matisse-o1: rgba(28, 82, 152, 0.1);
  --c-matisse-o2: rgba(28, 82, 152, 0.2);
  --c-mercury: #e9e9e9;
  --c-mine-shaft: #272727;
  --c-dark-green: #2e855e;
  --c-ocean-green: #46bc86;
  --c-picton-blue: #1fc8e7;
  --c-seashell: #f1f1f1;
  --c-silver: #c4c4c4;
  --c-silver-chalice: #a5a5a5;
  --c-slate-gray: #6b788e;
  --c-stratos: #00133e;
  --c-texas-rose: #fdbc51;
  --c-texas-rose-darker: #986c1f;
  --c-torch-red: #ff0033;
  --c-dark-red: #c70028;
  --c-l-pink: #fdd;
  --c-white: #fff;
  --c-white-darker: #e6e6e6;
  --c-white-o3: rgba(255, 255, 255, 0.3);
  --c-white-o6: rgba(255, 255, 255, 0.6);
  --c-blue-light: rgb(183, 219, 255);
  --c-blue-hight-o1: rgb(0, 97, 194);
  --c-blue-hight-o3: rgb(0, 115, 230);
  --c-grey-o1: rgb(175, 182, 194);
  --c-grey-o2: rgb(120, 124, 131);
  --c-shadow-dark: rgba(40, 51, 61, 0.08);
  --c-shadow-light: rgba(96, 104, 112, 0.16);

  --f-default-size: 1rem;
  --f-regular: "Open Sans", "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  background-color: var(--c-athens-gray);
  font-family: var(--f-regular);
  margin: 0px;
}

label {
  height: 3rem;
  line-height: 3rem;
}

.topBar {
  height: 100px;
}

.topBar img.logo {
  max-height: 100px;
}

.row {
  margin: 1rem 0;
}

.header {
  width: 100%;
  border-bottom: 1px solid var(--c-white-o3);
  padding-bottom: 1rem;
}

.panel {
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  margin: 2rem auto 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--c-white);
  box-shadow:
    var(--c-shadow-dark) 0px 0px 1px,
    var(--c-shadow-light) 0px 0.5px 2px;
  font-size: 1rem;
  color: rgb(29, 30, 35);
}

.intro {
  margin-top: 8rem;
}

.content p {
  line-height: 1.5rem;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

.wrapper {
  margin: 0 auto;
  max-width: var(--w-example-wrapped);
  padding: 1rem;
}

.card {
  border: 1px solid var(--c-mercury);
  padding: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.card.collapsed {
  max-height: 0;
  padding: 0;
  border: hidden;
}

.card :first-of-type {
  margin-top: 0;
}

form {
  max-width: 100%;
}

.form__field {
  padding: 1rem 0;
  position: relative;
}

h1 {
  color: var(--c-matisse);
  font-family: var(--f-regular);
  font-size: 3.125rem;
  font-weight: bold;
  letter-spacing: -0.02em;
  line-height: 1em;
}

header h1 {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-template-columns: 50% 50%;
}

header a {
  text-decoration: none;
}

header span {
  font-size: 1.35rem;
  line-height: 1;
}

header a {
  text-align: right;
  flex: 5;
}

header a:hover,
header a:active {
  text-decoration: underline;
}

@media screen and (min-width: 400px) {
  header span {
    font-size: 2rem;
  }
}

@media screen and (min-width: 800px) {
  header span {
    font-size: 2.6rem;
  }
}

h2 {
  color: var(--c-matisse);
  font-size: 1.2em;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}

h3 {
  color: var(--c-matisse);
  letter-spacing: -0.02em;
  font-family: var(--f-regular);
  margin-bottom: 0;
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.3em;
}

h3.error {
  color: var(--c-torch-red);
  font-size: 1.2em;
}

header + p {
  font-size: 0.9rem;
}

a {
  color: var(--c-matisse);
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}

a:focus,
a:hover {
  color: var(--c-deep-cove);
}

legend {
  font-family: var(--f-regular);
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1.3em;
}

label,
legend {
  line-height: 3rem;
  height: 3rem;
  width: 100%;
  color: var(--c-matisse);
}

fieldset label {
  font-size: 1rem;
  text-transform: none;
  letter-spacing: normal;
}

.label-terms {
  font-size: 1rem;
  line-height: normal;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
  padding-left: 0.2rem;
}

input[type="text"],
input[type="email"] {
  background-color: var(--c-white-o3);
  border: 1px solid var(--c-grey-o1);
  border-radius: 0.5rem;
  padding: 0px 1rem;
  height: 3rem;
  color: var(--c-black);
  font-family: var(--f-regular);
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5em;
  outline: 0;
  display: inline-block;
  max-width: 100%;
  width: 100%;
}

input[type="text"]:hover,
input[type="email"]:hover {
  border-color: var(--c-grey-o2);
}

input[type="text"]:focus,
input[type="email"]:focus {
  border: 1px solid var(--c-blue-light);
  outline: var(--c-blue-light) solid 0.125rem;
}

.ipbutton,
button {
  color: var(--c-white);
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.375rem 0.875rem;
  user-select: none;
  margin-bottom: 1.25rem;
  line-height: 1.5rem;
  align-items: center;
  background-color: var(--c-blue-hight-o1);
  border-radius: 0.5rem;
  border: 0.125rem solid var(--c-blue-hight-o3);
  border-width: 2px;
  cursor: pointer;
  display: inline-flex;
  font-family: var(--f-regular);
  font-style: normal;
  justify-content: center;
  outline: 0;
  text-align: center;
  text-decoration: none;
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}

.ipbutton,
button:hover {
  background-color: var(--c-blue-hight-o1);
  border-color: var(--c-blue-hight-o3);
}

/* iProov Web SDK overrides */
.progress {
  margin: 2rem 0;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  max-width: 100%;
  height: 3rem;
  background: var(--c-slate-gray);
}

.progress__inner {
  position: absolute;
  transition: width cubic-bezier(0.75, 0.02, 1, 0.27) 0.3s;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--c-matisse);
}

.progress__text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 3rem;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

.state {
  background-size: 80px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  margin-bottom: 1rem;
  padding-right: calc(80px + 1rem);
}

.state--passed {
  background-image: url("~/public/img/gpa-tick.png");
}

.state--canceled,
.state--error,
.state--failed {
  background-image: url("~/public/img/No-not-worked-woman-yellow.png");
  padding-right: calc(1rem + 80px);
}

[slot="ready"] h3 {
  font-size: 1.5rem;
}

.error-container {
  background-color: var(--c-l-pink);
  color: var(--c-dark-red);
  border-radius: 4px;
  border: 1px solid var(--c-dark-red);
  padding: 1rem;
  margin: 0 0 2rem 0;
}

#privacy_terms .error-container {
  margin-top: 0;
  margin-bottom: 1rem;
}

.error-container h3,
.error-container h4,
.error-container p {
  color: inherit;
  margin-bottom: 0;
}

.support-container {
  margin: 1rem 0 0;
  font-size: 0.8rem;
  background-repeat: no-repeat;
  padding-left: 2rem;
  background-size: 1.5rem;
  line-height: 1.5rem;
}

.support-container--ok {
  background-image: url("~/public/img/thumbs-up.png");
  color: var(--c-dark-green);
  font-weight: 700;
}

.support-container--error {
  background-image: url("~/public/img/thumbs-down.png");
  color: var(--c-torch-red);
}

.select {
  width: 100%;
}
